@let devices = (deviceList$ | async); @if(room() && (areRoomDocumentsLoading$ | async) === false) {
<div>
  @if(!documentTabs.length) {
  <app-no-data noDataText="No block diagram added" />
  } @else {
  <button
    (click)="openTabsSheet()"
    class="uppercase flex items-center justify-between rounded-md border border-ui-gray-light px-2 h-10 w-full mb-3">
    <span class="capitalize text-ui-dark font-nunito text-sm">{{ selectedTab }}</span>
    <i class="text-3xl icon-arrow_down text-ui-dark"></i>
  </button>
  @for (document of documents; track document) {
  <app-spaceplan
    [hidden]="!(devices && document.tab === selectedTab)"
    [spaceplan]="{
      id: room().id,
      documentReference: document.fileName,
      attachments: devices || [],
      parentSpaceId: room().parentSpaceId
    }"
    [documentTab]="selectedTab"
    [locationId]="locationId()"
    [canEdit]="false"
    [popover]="roomPopover" />
  } }
</div>
} @else {
<app-mobile-space-skeleton-animation />
}
