import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-mobile-block-diagram-tabs-sheet',
  standalone: true,
  imports: [CommonModule, BottomSheetMobileHeaderComponent, MatIconModule],
  templateUrl: './block-diagram-tabs-sheet.component.html',
})
export class BlockDiagramTabsSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BlockDiagramTabsSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { tabs: string[]; selectedTab: string }
  ) {}

  close(tab: string) {
    this._bottomSheetRef.dismiss(tab);
  }
}
