@if(currentFloor && locationId && currentFloor.documentReference) {
<app-spaceplan
  [spaceplan]="{
    id: currentFloor.id,
    documentReference: currentFloor.documentReference,
    attachments: currentFloor.rooms || [],
    parentSpaceId: currentFloor.parentSpaceId
  }"
  [locationId]="locationId"
  [currentAttachmentId]="roomId"
  [canEdit]="false"
  [popover]="roomPopover"
  [isMobile]="true" />
} @else {
<app-no-data noDataText="No floor plan yet" />
}
