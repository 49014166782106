import { Component, Inject, OnInit } from '@angular/core';
import { SpaceDetailsClass } from '@abstraction';
import { Store } from '@ngrx/store';
import { AppState, updateFloor } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@services';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SpaceStats, FloorSpaceModel, EntityStatus, LocationFullData, ScreenOrientations } from '@models';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgForOf, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PipesModule } from '@pipes';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';

enum Tabs {
  ROOMS = 'rooms',
  FLOOR_PLAN = 'floorPlan',
  INCIDENTS = 'incidents',
}

@Component({
  selector: 'app-mobile-floor-details',
  templateUrl: './floor-details.component.html',
  styleUrl: './floor-details.component.scss',
})
export class FloorDetailsComponent extends SpaceDetailsClass implements OnInit {
  protected readonly Tabs = Tabs;
  currentFloor!: FloorSpaceModel | undefined;
  floorStats!: SpaceStats;
  tab: Tabs = Tabs.ROOMS;

  get roomCount() {
    return this.currentFloor?.rooms?.length || 0;
  }

  constructor(
    public override store: Store<AppState>,
    public override dialog: MatDialog,
    public override appService: AppService,
    public override route: ActivatedRoute,
    private bottomSheet: MatBottomSheet
  ) {
    super(store, dialog, appService, route);
    this.routerWatcher();
    this.onLocationLoaded = () => {
      this.updateFloorRoomData();
    };
  }

  routerWatcher() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.floorId = params['floorId'];
      this.tab = Tabs.ROOMS;
      this.updateFloorRoomData();
    });
  }

  updateFloorRoomData() {
    if (!this.locationData?.floors) return;
    this.currentFloor = this.locationData.floors.find(floor => floor.id === this.floorId);
    this.updateFloorStats();
  }

  updateFloorStats() {
    this.floorStats = {
      incidentAlert: this.currentFloor?.incidentCountByStatuses.newCount || 0,
      incidentInProgress: this.currentFloor?.incidentCountByStatuses.inProgressCount || 0,
      deviceActive: this.currentFloor?.devicesByStatuses.activeCount || 0,
      devicePaused: this.currentFloor?.devicesByStatuses.pausedCount || 0,
      rooms: this.currentFloor?.rooms?.length || 0,
      floors: 0,
    };
  }

  ngOnInit() {
    this.getStoreData();
  }

  pauseFloor() {
    const isActive = this.currentFloor?.status !== EntityStatus.Paused;
    const title = isActive ? 'Pause' : 'Resume';
    const description = `Are you sure you want to ${isActive ? 'pause' : 'resume'} the ${
      this.currentFloor?.friendlyName
    }?`;
    const status = isActive ? EntityStatus.Paused : EntityStatus.Active;
    if (this.currentFloor?.id) {
      this.toggleFloorStatus(title, description, status, this.currentFloor.id);
    }
  }

  private toggleFloorStatus(title: string, description: string, status: EntityStatus, spaceId: string) {
    this.openConfirmationDialog({
      title,
      description,
      action: updateFloor({
        spaceId,
        locationId: this.locationData.id,
        data: { status },
      }),
    });
  }

  openFloorList() {
    this.bottomSheet.open(FloorListSheetComponent, {
      data: {
        locationData: this.locationData,
        floorId: this.floorId,
      },
    });
  }

  protected readonly ScreenOrientations = ScreenOrientations;
}

@Component({
  selector: 'app-mobile-floor-list',
  templateUrl: 'floor-list-sheet.html',
  standalone: true,
  imports: [PipesModule, NgForOf, RouterLink, NgIf, SearchInputComponent, BottomSheetMobileHeaderComponent],
})
export class FloorListSheetComponent {
  search = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<FloorListSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { locationData: LocationFullData; floorId: string }
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  openFloor() {
    this.close();
  }
}
