import { SelectionModel } from '@angular/cdk/collections';
import { Component, DestroyRef, Input, ViewChild, inject, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { DeviceData, EntityStatus, IncidentStatus, NormalizedDeviceData, UserRolesIds } from '@models';
import { exportDataToCSV, exportDataToJSON, naturalSorting, prepareDataForDownloading } from '@app-lib';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState, updateDeviceData, userRole } from '@ngrx-store';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DevicesFilterService, SessionStorageService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router, RouterLink } from '@angular/router';
import { DirectivesModule, VirtualTableScrollNormalizerDirective } from '@directives';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { MatTooltip } from '@angular/material/tooltip';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * TODO: remove once v2 is ready
 * @deprecated
 * use @devices/table component instead
 */
@Component({
  standalone: true,
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss'],
  imports: [
    MatTable,
    MatSortHeader,
    MatSort,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatCell,
    MatCellDef,
    MatCheckbox,
    RouterLink,
    DirectivesModule,
    NgClass,
    NoDataComponent,
    NgIf,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatHeaderRow,
    MatTooltip,
    AsyncPipe,
    IncidentStatusCountComponent,
    EntityStatusComponent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    VirtualTableScrollNormalizerDirective,
  ],
})
export class DevicesTableComponent implements AfterContentInit {
  protected readonly EntityStatus = EntityStatus;
  destroyRef = inject(DestroyRef);
  userRolesIds = UserRolesIds;
  @Input() tableId: string | null = null;
  @Input() selectEnabled = false;
  @Input() deviceData: DeviceData[] = [];
  @Input() isNoDataBigSize = false;
  @Input() isReportMode = false;
  @Input() isMobile = false;
  @Input() isInjected = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource!: MatTableDataSource<NormalizedDeviceData>;
  selection = new SelectionModel<NormalizedDeviceData>(true, []);

  // Filters List
  userRole$: Observable<UserRolesIds | undefined>;
  incidentStatus = IncidentStatus;

  @ViewChild('tableContent') set paginatorBlock(element: MatTable<any>) {
    if (element) {
      this.dataSource.sort = this.sort;
      this.changeDetectionRef.detectChanges();
    }
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private changeDetectionRef: ChangeDetectorRef,
    public devicesFilterService: DevicesFilterService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {
    this.userRole$ = this.store.select(userRole);
    this.devicesFilterService.filteredDevices$.pipe(takeUntilDestroyed()).subscribe(devices => {
      this.initTableData(devices);
    });
  }

  initTableData(devices: NormalizedDeviceData[]) {
    this.dataSource = new MatTableDataSource<NormalizedDeviceData>(devices);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = naturalSorting;
  }

  ngAfterContentInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.clearSelection();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: NormalizedDeviceData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  clearSelection() {
    this.selection.clear();
  }

  resumeDevices() {
    this.clearSelection();
  }

  pauseDevices() {
    this.clearSelection();
  }

  openConfirmationDialog({
    title,
    description,
    locationId,
    deviceId,
    data,
  }: {
    title: string;
    description: string;
    locationId: string;
    deviceId: string;
    data: { [_: string]: string };
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation && locationId && deviceId && data) {
          this.store.dispatch(updateDeviceData({ locationId, deviceId, data }));
        }
      });
  }

  resumeDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Resume',
      description: `Are you sure you want to resume ${device.deviceName} ?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  pauseDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Pause',
      description: `Are you sure you want to pause ${device.deviceName} ?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Paused },
    });
  }

  archiveDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Add to Archive',
      description: `Are you sure you want to add ${device.deviceName} to archive?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Archived },
    });
  }

  restoreDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      title: 'Restore',
      description: `Are you sure you want to restore ${device.deviceName} from archive?`,
      locationId: device.buildingId,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  deleteDevicePermanently(id: string) {
    console.log(`device with id: ${id} was permanently deleted`);
  }

  prepareDevicesDataForDownloading() {
    const columns = this.devicesFilterService.displayedColumns
      .filter(column => column !== 'actions' && column !== 'status')
      .map(column => (column === 'index' ? 'id' : column));

    return prepareDataForDownloading(columns, this.dataSource.filteredData);
  }

  exportDevicesToCSV() {
    exportDataToCSV(this.prepareDevicesDataForDownloading(), 'devices report');
  }

  exportDevicesToJSON() {
    exportDataToJSON(this.prepareDevicesDataForDownloading(), 'devices report');
  }

  goToDevice(device: NormalizedDeviceData) {
    if (this.devicesFilterService.isReportMode) return;
    const url = ['/devices', device.buildingId, device.id];
    if (this.tableId) {
      this.sessionStorageService.saveLastSelectedItems(this.tableId, { title: device.deviceName, url });
    }
    this.router.navigate(url);
  }
}
