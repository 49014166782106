<app-bottom-sheet-mobile-header [showCloseButton]="false" />
<div class="mobile-action-sheet">
  <div class="mobile-action-sheet-content">
    <ul class="mobile-single-filter-select">
      <li *ngFor="let tab of data.tabs" [class.checked]="tab === data.selectedTab" (click)="close(tab)">
        <span class="capitalize">{{ tab }}</span>
        <i class="icon-check text-ui-green-light text-3xl"></i>
      </li>
    </ul>
  </div>
</div>
