import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMainComponent } from './containers/dashboard-main/dashboard-main.component';
import { RouterModule } from '@angular/router';
import { BuildingDetailsComponent } from '@app-mobile/modules/dashboard/containers/building-details/building-details.component';
import { FloorDetailsComponent } from './containers/floor-details/floor-details.component';
import { RoomDetailsComponent } from './containers/room-details/room-details.component';
import { MobileMainHeaderComponent } from '@standalone/mobile-main-header/mobile-main-header.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BuildingFiltersSheetComponent } from './components/building-filters-sheet/building-filters-sheet.component';
import { locationsResolver, mobileLocationStateResolver } from '@ngrx-store';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MapFiltersSheetComponent } from './components/map-filters-sheet/map-filters-sheet.component';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { MobileButtonsBarComponent } from '@standalone/mobile-buttons-bar/mobile-buttons-bar.component';
import { BuildingAddressBarComponent } from '@app-mobile/modules/dashboard/components/building-address-bar/building-address-bar.component';
import { MatCard } from '@angular/material/card';
import { IncidentInfoBlockComponent } from '@app-mobile/modules/dashboard/components/incident-info-block/incident-info-block.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BuildingListItemComponent } from './components/building-list-item/building-list-item.component';
import { PipesModule } from '@pipes';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';
import { IncidentsTabComponent } from '@app-mobile/modules/dashboard/components/incidents-tab/incidents-tab.component';
import { MatIcon } from '@angular/material/icon';
import { DirectivesModule } from '@directives';
import { FloorplanTabComponent } from './components/floorplan-tab/floorplan-tab.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { DashboardMapComponent } from '@standalone/dashboard-map.component/dashboard-map.component';
import { SpaceplanComponent } from '@standalone/_spaceplan/spaceplan/spaceplan.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { SiteTableComponent } from '@standalone/_tables/site-table/site-table.component';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { RoomListTableComponent } from '@standalone/_tables/room-list-table/room-list-table.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { BlockDiagramTabComponent } from './components/block-diagram-tab/block-diagram-tab.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';

@NgModule({
  declarations: [
    DashboardMainComponent,
    BuildingDetailsComponent,
    FloorDetailsComponent,
    RoomDetailsComponent,
    BuildingFiltersSheetComponent,
    MapFiltersSheetComponent,
    BuildingAddressBarComponent,
    IncidentInfoBlockComponent,
    BuildingListItemComponent,
    IncidentsTabComponent,
    FloorplanTabComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    DirectivesModule,
    RouterModule.forChild([
      {
        path: '',
        component: DashboardMainComponent,
        data: { showMenu: true },
        resolve: {
          locations: locationsResolver,
        },
      },
      {
        path: ':locationId',
        component: BuildingDetailsComponent,
        data: { showMenu: false },
        resolve: { mobileLocationStateResolver },
      },
      {
        path: ':locationId/:floorId',
        component: FloorDetailsComponent,
        data: { showMenu: false },
        resolve: { mobileLocationStateResolver },
      },
      {
        path: ':locationId/:floorId/:roomId',
        component: RoomDetailsComponent,
        data: { showMenu: false },
        resolve: { mobileLocationStateResolver },
      },
    ]),
    MobileMainHeaderComponent,
    MatButtonToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    NestedPageHeaderComponent,
    MobileButtonsBarComponent,
    MatCard,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule,
    SpaceSkeletonAnimationComponent,
    MatIcon,
    SearchInputComponent,
    BottomSheetMobileHeaderComponent,
    EntityStatusComponent,
    DashboardMapComponent,
    SpaceplanComponent,
    IncidentStatusCountComponent,
    SiteTableComponent,
    DevicesTableComponent,
    IncidentsTableComponent,
    RoomListTableComponent,
    SkeletonTableComponent,
    BlockDiagramTabComponent,
    NoDataComponent,
  ],
})
export class DashboardModule {}
