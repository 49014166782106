<mat-card class="card">
  <ul>
    <li class="flex items-center test-building-name">
      <i class="icon-ic_building text-3xl mr-3 text-ui-gray-100"></i><strong>{{ buildingName }}</strong>
    </li>
    <li class="flex items-center test-building-address">
      <i class="icon-ic_location text-3xl mr-3 text-ui-gray-100"></i>{{ buildingAddress }}
    </li>
    <li *ngIf="buildingStatus" class="test-building-status flex items-center">
      <i class="icon-ic_status text-3xl mr-3 text-ui-gray-100"></i>
      <app-entity-status fontSize="base" [status]="buildingStatus" />
    </li>
    <li *ngIf="floorStatus" class="test-floor-status flex items-center">
      <i class="icon-ic_status text-3xl mr-3 text-ui-gray-100"></i>
      <app-entity-status fontSize="base" [status]="floorStatus" />
    </li>
    <li *ngIf="roomStatus" class="test-room-status flex items-center">
      <i class="icon-ic_status text-3xl mr-3 text-ui-gray-100"></i>
      <app-entity-status fontSize="base" [status]="roomStatus" />
    </li>
  </ul>
</mat-card>
