import { Component, DestroyRef, effect, input, untracked } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceData, ImageDocument, RoomSpaceModel } from '@models';
import { generateDocumentType } from '@app-lib';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AppState, areRoomDocumentsLoading, getDeviceBySpaceId, getRoomDocuments, getRoomImages } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SpaceplanComponent } from '@standalone/_spaceplan/spaceplan/spaceplan.component';
import { RoomPopoverComponent } from '@standalone/room-popover/room-popover.component';

import { BlockDiagramTabsSheetComponent } from '../block-diagram-tabs-sheet/block-diagram-tabs-sheet.component';

@Component({
  selector: 'app-mobile-block-diagram-tab',
  standalone: true,
  imports: [CommonModule, SpaceSkeletonAnimationComponent, NoDataComponent, SpaceplanComponent],
  templateUrl: './block-diagram-tab.component.html',
})
export class BlockDiagramTabComponent {
  locationId = input.required<string>();
  room = input.required<RoomSpaceModel>();

  areRoomDocumentsLoading$: Observable<boolean>;
  deviceList$: Observable<DeviceData[]> | undefined;
  selectedTab = '';
  documentTabs: string[] = [];
  documents: ImageDocument[] = [];
  roomPopover = RoomPopoverComponent;

  constructor(private store: Store<AppState>, private destroyRef: DestroyRef, private bottomSheet: MatBottomSheet) {
    this.areRoomDocumentsLoading$ = this.store.select(areRoomDocumentsLoading);

    let previousRoomId: string | undefined;

    effect(() => {
      const currentRoom = this.room();
      if (currentRoom?.id !== previousRoomId) {
        previousRoomId = currentRoom?.id;
        this.initData(currentRoom);
      }
    });
  }

  initData(currentRoom: RoomSpaceModel) {
    untracked(() => {
      if (currentRoom?.parentSpaceId) {
        this.store.dispatch(
          getRoomDocuments({
            locationId: this.locationId(),
            floorId: currentRoom.parentSpaceId,
            roomId: currentRoom.id,
          })
        );
      }
    });
    this.deviceList$ = this.store.select(getDeviceBySpaceId(currentRoom.id));
    this.store
      .select(getRoomImages)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(documents => {
        this.documents = documents;
        this.generateDocumentTabs(documents);
      });
  }

  documentTabChange(change: MatButtonToggleChange) {
    this.selectedTab = change.value;
  }

  generateDocumentTabs(documents: ImageDocument[]) {
    const docTabs = documents.map(({ tab }, index) => tab || generateDocumentType(index));
    this.documentTabs = [...new Set([...this.documentTabs, ...docTabs].filter(item => docTabs.includes(item)))];
    this.selectedTab = this.documentTabs[0] || '';
  }

  openTabsSheet() {
    this.bottomSheet
      .open(BlockDiagramTabsSheetComponent, {
        data: {
          tabs: this.documentTabs,
          selectedTab: this.selectedTab,
        },
      })
      .afterDismissed()
      .subscribe((selectedTab: string) => {
        this.selectedTab = selectedTab;
      });
  }
}
